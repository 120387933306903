import React from 'react';
import { graphql, type PageProps } from 'gatsby';

import { Layout } from 'modules/ui';
import { BlogPostsPage, BlogSeo } from 'modules/blog';
import type { BlogPost, BlogPostsPageContext, PostTag } from 'modules/blog/types';

interface DataProps {
    allStrapiPost: {
        nodes: BlogPost[];
    };
    strapiTag: PostTag;
}

const BlogPostsTag = ({ data, pageContext }: PageProps<DataProps, BlogPostsPageContext>) => (
    <>
        <BlogSeo title={data.strapiTag.title} description="" />
        <Layout>
            <BlogPostsPage
                title={data.strapiTag.title}
                posts={data.allStrapiPost.nodes}
                basePath={`/blog/tag/${data.strapiTag.slug}`}
                pageContext={pageContext}
            />
        </Layout>
    </>
);

export const pageQuery = graphql`
    query ($slug: String!, $skip: Int!, $limit: Int!, $localeRegex: String!) {
        strapiTag(slug: { eq: $slug }, locale: { regex: $localeRegex }) {
            ...PostTag
        }
        allStrapiPost(
            filter: { tags: { elemMatch: { slug: { eq: $slug } } }, locale: { regex: $localeRegex } }
            sort: { fields: publishedAt, order: DESC }
            limit: $limit
            skip: $skip
        ) {
            nodes {
                ...BlogPost
            }
        }
    }
`;

export default BlogPostsTag;
